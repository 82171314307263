
// vue
import { defineComponent, ref, computed } from 'vue';

// primevue
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';

// proj4
import proj4 from 'proj4';

export default defineComponent({
    components: {
        InputNumber,
        InputSwitch,
    },
    setup() {
        // reactive
        const x = ref<number | null>(null);
        const y = ref<number | null>(null);
        const isSwitchOrder = ref(false);

        // computed
        const lonLat = computed(() => {
            if (x.value != null && y.value != null) {
                return proj4(
                    '+proj=tmerc +lat_0=43.8518772199998 +lon_0=-87.7373839300002 +k=1 +x_0=2804.62 +y_0=3299.51 +ellps=GRS80 +units=us-ft +no_defs',
                    'EPSG:4326',
                    [x.value, y.value],
                );
            }
            return [0, 0];
        });

        return {
            x,
            y,
            lonLat,
            isSwitchOrder,
        };
    },
});
